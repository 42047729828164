/* You can add global styles to this file, and also import other style files */
/*@import '~@angular/material/prebuilt-themes/indigo-pink.css';*/

body {
    margin: 0px;
    //background-image: url("assets/My-Agathon-Login-hell.png");
    //background-size: cover;
    //background-repeat: no-repeat;

    .content {
        min-height: 100vh
    }
}

/*
@media (max-width: 576px) {
    body {
        background-size: auto;
    }
}
*/
