@import '~@angular/material/theming';
@include mat-core();

/* Agathon CI colors */
$ag-silver: (
    50 : #fcfcfc,
    100 : #f8f8f8,
    200 : #f4f4f4,
    300 : #efefef,
    400 : #ebebeb,
    500 : #e8e8e8,
    600 : #e5e5e5,
    700 : #e2e2e2,
    800 : #dedede,
    900 : #d8d8d8,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$ag-blue: (
    50 : #e1e6e9,
    100 : #b5c1c9,
    200 : #8398a5,
    300 : #516f81,
    400 : #2c5066,
    500 : #07314b,
    600 : #062c44,
    700 : #05253b,
    800 : #041f33,
    900 : #021323,
    A100 : #5fa1ff,
    A200 : #2c82ff,
    A400 : #0066f8,
    A700 : #005bdf,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$ag-anthracite: (
    50 : #e5e6e7,
    100 : #bfc1c2,
    200 : #959899,
    300 : #6a6f70,
    400 : #4a5052,
    500 : #2a3133,
    600 : #252c2e,
    700 : #1f2527,
    800 : #191f20,
    900 : #0f1314,
    A100 : #5ad6ff,
    A200 : #27c9ff,
    A400 : #00b6f3,
    A700 : #00a3da,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #ffffff,
    )
);

$ag-red: (
    50 : #fbe4e5,
    100 : #f5bcbe,
    200 : #ef9092,
    300 : #e86366,
    400 : #e34146,
    500 : #de2025,
    600 : #da1c21,
    700 : #d5181b,
    800 : #d11316,
    900 : #c80b0d,
    A100 : #fff4f4,
    A200 : #ffc1c1,
    A400 : #ff8e8f,
    A700 : #ff7475,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);



$agathon-app-primary: mat-palette($ag-silver, A100);
$agathon-app-accent: mat-palette($ag-blue);

$agathon-app-warn: mat-palette($mat-red);

$agathon-app-theme: mat-light-theme($agathon-app-primary, $agathon-app-accent, $agathon-app-warn);

@include angular-material-theme($agathon-app-theme);



$dark-app-primary: mat-palette($ag-anthracite);
$dark-app-accent: mat-palette($ag-red);

$dark-app-warn: mat-palette($mat-red);

$dark-app-theme: mat-dark-theme($dark-app-primary, $dark-app-accent, $dark-app-warn);

.darkMode {
    @include angular-material-theme($dark-app-theme);
}
